import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChatboxSendersList } from "./Conversations/ChatboxSendersList";
import { ChatboxChat } from "./Chat/ChatboxChat";
import { ChatboxChatInput } from "./Chat/ChatboxChatInput";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { useUser } from "../../utils/contexts/userContext";
import { getUserConversations } from "../../utils/fetchs/conversationFetchs";
import { ChatboxChatHeader } from "./Chat/ChatboxChatHeader";
import updateHead from "../../utils/helpers/updatePageHead";
import Loader from "../../components/Common/Loader/Loader";
import ConvParams from "./Chat/ConvParams";
import { ChatboxContext } from "../../utils/contexts/chatboxContext";
import { isMobile } from "react-device-detect";
import { checkToken } from "../../utils/fetchs/authFetchs";

export default function Chatbox() {
  const cssPath = "/assets/css/pages/chatbox.css";
  const title = "Wive | Messagerie";

  const { user } = useUser();
  const [convs, setConvs] = useState(null);
  const {
    activeConv,
    setActiveConv,
    sharedPubli,
    setSharedPubli,
    showNavbar,
    showConvFirst,
    setShowNavbar,
  } = useContext(ChatboxContext);
  const navigate = useNavigate();
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const [showChat, setShowChat] = useState(
    mobileDisplay && !showConvFirst ? false : true
  );
  if (user.token && !user.verified) {
    navigate("/two-factor-auth");
  }
  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    checkToken(user);
  }, [user]);

  useEffect(() => {
    getUserConversations(user).then((conversations) => {
      if (conversations && conversations.length > 0) {
        conversations.sort((c1, c2) =>
          c1.lastUpdated > c2.lastUpdated ? -1 : 1
        );
        setConvs(conversations);
      } else {
        setConvs([]);
      }
    });
  }, [user]);

  useEffect(() => {
    if (convs && convs.length > 0 && !activeConv) {
      setActiveConv(convs.sort((c1, c2) => c1.lastUpdated > c2.lastUpdated)[0]);
    }
  }, [convs]);

  useEffect(() => {
    setShowNavbar(!showChat);
  }, [showChat, setShowNavbar]);

  if (!convs || !activeConv) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ title, cssPath })}
      <section
        id="chatbox_container"
        style={{
          height: showChat ? "calc(100vh-100px)" : "100vh",
          marginTop: showChat ? "0" : "100px",
        }}
      >
        {/* Mobile display */}
        {mobileDisplay && !showChat ? (
          <div
            className="chatbox_left_col"
            style={{
              width: showChat ? "" : "100%",
            }}
          >
            <ChatboxSendersList
              conversations={convs}
              showChat={showChat}
              setShowChat={setShowChat}
              activeConv={activeConv}
              handleActiveConv={setActiveConv}
            />
          </div>
        ) : null}

        <BackgroundGradientLur />
        {mobileDisplay && showChat ? (
          <div className="chatbox_right_col">
            <ChatboxChatHeader
              activeConv={activeConv}
              actualUser={user}
              showThirdSection={showThirdSection}
              setShowThirdSection={setShowThirdSection}
              showChat={showChat}
              setShowChat={setShowChat}
              setShowNavbar={setShowNavbar}
            />
            <ChatboxChat activeConv={activeConv} actualUser={user} />
          </div>
        ) : null}

        {/* Website display */}
        {!mobileDisplay && (
          <>
            <div
              className="chatbox_left_col"
              style={{
                width: showChat ? "" : "100%",
              }}
            >
              <ChatboxSendersList
                conversations={convs}
                showChat={showChat}
                setShowChat={setShowChat}
                activeConv={activeConv}
                handleActiveConv={setActiveConv}
              />
            </div>

            <div className="chatbox_right_col">
              <ChatboxChatHeader
                activeConv={activeConv}
                actualUser={user}
                showThirdSection={showThirdSection}
                setShowThirdSection={setShowThirdSection}
                showChat={showChat}
                setShowChat={setShowChat}
                setShowNavbar={setShowNavbar}
              />
              <ChatboxChat activeConv={activeConv} actualUser={user} />
            </div>
          </>
        )}
        {mobileDisplay && showChat && (
          <ChatboxChatInput
            activeConv={activeConv}
            actualUser={user}
            sharedPubli={sharedPubli}
            setSharedPubli={setSharedPubli}
          />
        )}
        {!mobileDisplay && (
          <ChatboxChatInput
            activeConv={activeConv}
            actualUser={user}
            sharedPubli={sharedPubli}
            setSharedPubli={setSharedPubli}
          />
        )}
        {showThirdSection && (
          <div className="chatbox_third_col">
            <ConvParams activeConv={activeConv} actualUser={user} />
          </div>
        )}
      </section>
    </>
  );
}
