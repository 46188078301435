import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import PublicationSettings from "../tmpl/PublicationSettings";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { getEventById } from "../../../../utils/fetchs/eventFetchs";
import { getUserByUserId } from "../../../../utils/fetchs/userFetchs";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import { updatePublication } from "../../../../utils/fetchs/publicationFetchs";
import { PublicationFooter } from "../tmpl/PublicationFooter";
import { UserCard } from "../../../Entities/User/UserCard";
import { isMobile } from "react-device-detect";
import {
  displayEventDate,
  formatEventTitle,
} from "../../../../utils/helpers/eventHelper";
import CalendarCard from "../tmpl/CalendarCard";
import MapInner from "../../../Entities/Map/MapInner";
import { MapContainer } from "react-leaflet";
import GradientButton from "../../Buttons/GradientButton";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import {
  getLikes,
  likeContent,
  unlikeContent,
} from "../../../../utils/fetchs/likeFetchs";
import { getSalesByEventId } from "../../../../utils/fetchs/saleFetch";
const api_url = getImgUrl();

export function PublicationEventRelease({ publication, loggedInUser }) {
  const [event, setEvent] = useState(null);
  const [isPhone, setisPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const model = "Event";
  const [creator, setCreator] = useState(null);
  const [isLiked, setIsLiked] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);

  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    getEventById(publication.event_id, loggedInUser).then((event) =>
      setEvent(event)
    );

    getUserByUserId(publication.creator_id).then((user) => setCreator(user));
  }, [publication]);

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        const likes = await getLikes(event._id, model);
        setLikesCount(likes.length);
        setIsLiked(likes.some((like) => like.user === loggedInUser._id));
        const participants = await getSalesByEventId(loggedInUser, event._id);
        setParticipantCount(participants);
      } catch (error) {
        console.error("Failed to fetch likes", error);
      }
    };

    if (event) {
      fetchInfos();
    }
  }, [event, loggedInUser._id]);

  if (!event || !creator) {
    return <Loader />;
  }

  const position = [
    event.event_location_coordinates[0],
    event.event_location_coordinates[1],
  ];

  const handleLikeClick = async () => {
    try {
      if (isLiked) {
        await unlikeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount - 1);
      } else {
        await likeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error("Failed to toggle like", error);
    }
  };
  return (
    <>
      <li className="post">
        <div className="publi_event_release" />

        <div className="info">
          <Link to={`/event/${event._id}`}>
            <p className="event_title text_gradient">{event.event_title}</p>
          </Link>
          <PublicationSettings />
        </div>
        <div className="info post_infos">
          <div className="event_creator">
            <p>Par :</p>
            <div className="info">
              <UserCard user={creator} />
            </div>
          </div>
          <div className="flex_column">
            <p className="event_participants">
              <FontAwesomeIcon icon={faUsers} className="icon" />
              {participantCount} participants
            </p>
            <ParticipatingFriendList participants={event.participants} />
          </div>
        </div>
        <div className="post_image_container">
          <Link to={`/event/${event._id}`}>
            <img
              src={`${api_url}/uploads/events/${event.event_picture}`}
              className="post_image"
              alt="publication"
            />
          </Link>
          <CalendarCard event={event} />
          <div className="post_infos_container">
            {isPhone ? (
              <img
                src={
                  "/assets/images/heart" +
                  (isLiked ? "Filled" : "") +
                  "64px.png"
                }
                className="post_like"
                alt="like"
                onClick={handleLikeClick}
              />
            ) : (
              <GradientButton
                type={"like"}
                padding={"0px 25px"}
                filled={isLiked}
                onClick={handleLikeClick}
              />
            )}

            <p className="like_count">{likesCount} j'aimes</p>

            <MapContainer center={position} zoom={13}>
              <MapInner events={[event]} handleBounds={setBounds} />
            </MapContainer>
          </div>
        </div>
        <PublicationFooter
          publication={publication}
          event={event}
          loggedInUser={loggedInUser}
        />
      </li>
    </>
  );
}
