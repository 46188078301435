import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function checkLogin(email, password) {
  try {
    const response = await fetch(`${api_url}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    switch (response.status) {
      case 200:
        return await response.json();
      case 400:
        return 400;
      default:
        throw new Error("Login not ok");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function checkToken(loggedInUser) {
  try {
    if (loggedInUser._id) {
      const tk = await loggedInUser.token;
      const response = await fetch(`${api_url}/auth/checktoken`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tk,
        },
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error(
            `Le token de connection de : ${loggedInUser.username} n'est pas valide.`
          );
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function verifyEmail(token) {
  try {
    const response = await fetch(
      `${api_url}/auth/verify-email?token=${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      case 400:
        return 400;
      default:
        throw new Error("Email verification not ok");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function sendVerificationEmail(email, force) {
  try {
    const response = await fetch(`${api_url}/auth/send-verification-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, force }),
    });

    switch (response.status) {
      case 200:
        return response;
      case 404:
        return 404;
      case 500:
        return 500;
      default:
        throw new Error("Error sending verification email");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function sendPasswordResetEmail(email) {
  try {
    const response = await fetch(`${api_url}/auth/reset-password-request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 404:
        return { error: "User not found" };
      case 500:
        return { error: "Error sending email" };
      default:
        throw new Error("Unknown error occurred during password reset request");
    }
  } catch (error) {
    console.error(error.message);
    return { error: "Network error or server unreachable" };
  }
}

export async function resetPassword(token, newPassword) {
  try {
    const response = await fetch(`${api_url}/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, newPassword }),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 400:
        return { error: "Invalid or expired token" };
      default:
        throw new Error("Password reset failed");
    }
  } catch (error) {
    console.error(error.message);
    return { error: "Network error or server unreachable" };
  }
}
("");
