import React from "react";
import { TileLayer, Marker, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import updateHead from "../../../utils/helpers/updatePageHead";

export default function MapInner({ events, handleBounds }) {
  const cssPath = "/assets/css/pages/map.css";

  const map = useMapEvent("moveend", (e) => {
    handleBounds(map.getBounds());
  });

  const customIcon = L.icon({
    iconUrl: "../assets/images/pin-map.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <>
      {updateHead({ cssPath })}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={
          "https://api.mapbox.com/styles/v1/martindvt/cla12ofem001714pagsw8ckos/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFydGluZHZ0IiwiYSI6ImNsYTEyaW1tcTAzOHAzb29qcGE2cGl1b2wifQ.U50_uUAHELrTfiIpobof7Q"
        }
      />
      {events.map((e) => (
        <Marker
          key={e._id}
          position={e.event_location_coordinates}
          icon={customIcon}
        ></Marker>
      ))}
    </>
  );
}
