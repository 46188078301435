import { getImgUrl } from "../../../utils/contexts/apiContext";

const api_url = getImgUrl();

export default function SingleUserPicture({ user }) {
  return (
    <div className="pp_wrapper">
      <img
        src={`${api_url}/uploads/profilepics/${user.profilepic}`}
        alt="profile_picture"
        className="chatbox_sender_pp chatbox_cover"
      />
    </div>
  );
}
