import React, { useState, useRef, useEffect } from "react";
import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";
import { createUser } from "../../../utils/fetchs/userFetchs";
import { useSpring, animated } from "react-spring";
import { uploadProfilePicture } from "../../../utils/fetchs/imageFetch";
import { getAllUsernames } from "../../../utils/fetchs/userFetchs";
import { translateXMinus30 } from "../../../utils/animations/animations";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { checkLogin } from "../../../utils/fetchs/authFetchs";

export default function Register() {
  const navigate = useNavigate();
  const animation = translateXMinus30();
  const { login } = useUser();

  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const [usernames, setUsernames] = useState([]);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);

  const [currentStep, setCurrentStep] = useState(1);
  const form = useRef();
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
    lastname: "",
    firstname: "",
    birthdate: "",
    description: "",
    phonenumber: "",
    profilepic: "",
  });

  useEffect(() => {
    getAllUsernames().then((data) => setUsernames(data));
  }, []);

  const checkUsernameValidity = async (username) => {
    if (usernames.includes(username)) {
      setInputErrorMessage("Le pseudo choisi est déjà utilisé");
      setIsUsernameAvailable(false);
    }
  };

  const validateStep1 = () => {
    // Nettoyer l'email en supprimant les espaces en début et fin de chaîne
    user.email = user.email.trim();

    // Valider l'e-mail
    const isValidMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email);
    if (!isValidMail) {
      setInputErrorMessage("L'adresse mail n'est pas valide.");
      return false;
    }

    // Valider les mots de passe
    if (!user.password.trim() || !user.confirmPassword.trim()) {
      setInputErrorMessage("Veuillez entrer les deux mots de passe.");
      return false;
    } else if (user.password !== user.confirmPassword) {
      setInputErrorMessage("Les mots de passe ne correspondent pas.");
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    user.username = user.username.trim();

    if (!user.username || !isUsernameAvailable) {
      setInputErrorMessage("Le pseudo choisi n'est pas valide.");
      return false;
    }
    if (!user.firstname || !user.lastname) {
      setInputErrorMessage("Veuillez entrer un nom complet valide.");
      return false;
    }

    return true;
  };

  const nextStep = async () => {
    switch (currentStep) {
      case 1:
        validateStep1()
          ? (setInputErrorMessage(null), setCurrentStep(currentStep + 1))
          : null;
        break;
      case 2:
        validateStep2()
          ? (setInputErrorMessage(null), setCurrentStep(currentStep + 1))
          : null;
        break;
      case 3:
        handleRegister();
        break;
      default:
        break;
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setInputErrorMessage(null);
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRegister = async () => {
    if (user.profilepic) {
      const profilepicurl = await uploadProfilePicture(user.profilepic);
      user.profilepic = profilepicurl;
    }

    const createdUser = await createUser(user);
    console.log(createdUser);
    const sessionInfos = await checkLogin(user.email, user.password);
    console.log(sessionInfos);

    login(sessionInfos.user, sessionInfos.token);
    navigate("/two-factor-auth");
  };

  return (
    <>
      <form
        className="login_form"
        id="login_form"
        onSubmit={handleRegister}
        ref={form}
      >
        {(() => {
          switch (currentStep) {
            case 1:
              return <RegisterStep1 user={user} setUser={setUser} />;
            case 2:
              return (
                <RegisterStep2
                  user={user}
                  setUser={setUser}
                  usernames={usernames}
                  checkUsernameValidity={checkUsernameValidity}
                />
              );
            case 3:
              return <RegisterStep3 user={user} setUser={setUser} />;
            default:
              return null;
          }
        })()}

        <animated.div style={animation} className="form_group">
          <p className="error_message">{inputErrorMessage}</p>
          <p className="fields">
            {currentStep < 3
              ? "* Champs obligatoires"
              : "* Aucun champ n'est obligatoire, mais c'est l'occasion de te faire une beauté 😉"}
          </p>
          <GradientButton
            text={currentStep < 3 ? "Suivant" : "Créer votre profil"}
            icon={currentStep < 3 ? faArrowRight : faRightToBracket}
            textfirst={true}
            onClick={(e) => {
              e.preventDefault();
              nextStep();
            }}
          />
        </animated.div>
        <animated.div style={animation} className="form_group">
          {currentStep > 1 && (
            <button className="back_btn" onClick={prevStep}>
              <FontAwesomeIcon icon={faArrowLeft} className="icon" /> Retour
            </button>
          )}
        </animated.div>
      </form>
    </>
  );
}
