import React from "react";
import { faCalendar, faTicket } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../../../components/Common/Buttons/GradientButton";
import { useUser } from "../../../../utils/contexts/userContext";
import { createSale } from "../../../../utils/fetchs/saleFetch";
import { useNavigate } from "react-router-dom";

export function TicketSlide({ ticket, onReservation }) {
  const { user } = useUser();
  const navigate = useNavigate();
  const handleReservation = async () => {
    try {
      if (!user.email) {
        return navigate("/login-register");
      }
      const saleData = {
        billetId: ticket._id,
        eventId: ticket.event_id,
        userId: user._id,
      };

      // Appelle la fonction createSale pour créer une nouvelle vente
      const response = await createSale(user, saleData);

      if (response) {
        if (onReservation) {
          onReservation(); // Rafraîchit l'état de la participation
        }
      } else {
        console.error("Erreur lors de la réservation");
      }
    } catch (error) {
      console.error("Erreur lors de la création de la vente :", error);
    }
  };
  return (
    <div className="ticket_wrapper">
      <p>{ticket.name}</p>
      <p>{ticket.description}</p>
      <div className="ticket_flex">
        <p className="text_gradient">{ticket.price.toFixed(2)}€</p>

        {ticket.price > 0 ? (
          <GradientButton icon={faTicket} round={true} />
        ) : (
          <GradientButton
            text={"Reserver"}
            icon={faCalendar}
            textfirst={true}
            onClick={handleReservation}
          />
        )}

        {/*For ticketting 
       <Link to={`/event/${event_title}/payements`}>
   
        {/*For ticketting         
          </Link>
        */}
      </div>
    </div>
  );
}
