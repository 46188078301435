import React, { useState, useEffect } from "react";
import { useUser } from "../../../utils/contexts/userContext";
import { SpinningLoader } from "../../Common/Loader/SpinningLoader";
import SingleUserPicture from "../User/SingleUserPicture";
import { getFollowers } from "../../../utils/fetchs/followRequestFetchs";

export function ParticipatingFriendList({ participants }) {
  const { user } = useUser();
  const [friendsList, setfriendsList] = useState();

  useEffect(() => {
    getFollowers(user, user._id).then((populatedUser) => {
      if (populatedUser && populatedUser.following) {
        const participatingFriends = populatedUser.following.filter((friend) =>
          participants.includes(friend._id)
        );
        setfriendsList(participatingFriends);
      }
    });
  }, []);

  if (!friendsList) {
    return <SpinningLoader />;
  }

  return (
    <>
      <div className="list_wrap">
        {friendsList.length > 0 ? (
          <>
            <ul className="friends_list">
              {friendsList
                .filter((_, i) => i < 3)
                .map((friend) => (
                  <li key={friend._id} className="friend_card">
                    <SingleUserPicture user={friend} />
                  </li>
                ))}
            </ul>
            {friendsList.length <= 3 ? (
              <p className="list_count_text">
                {friendsList.length !== 1 ? `participent` : `participe`} à cet
                événement.
              </p>
            ) : (
              <p className="list_count_text">
                et {friendsList.length - 3} autres amis y participent.
              </p>
            )}
          </>
        ) : (
          <p className="list_count_text_placeholder">
            ☝️ Soyez le premier de vos amis à y participer
          </p>
        )}
      </div>
    </>
  );
}
