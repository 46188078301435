import { Link } from "react-router-dom";
import { animated } from "react-spring";
import { translateYMinus100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export function UserCard({ user, link = true, clearInput }) {
  const cardContent = (
    <>
      <div className="profile_picture pp_wrapper">
        <img
          src={`${api_url}/uploads/profilepics/${user.profilepic}`}
          alt="profil"
          className="profile_picture"
        />
      </div>
      <p className="username">@{user.username}</p>
    </>
  );
  return (
    <>
      <animated.div style={translateYMinus100()}>
        {link ? (
          <Link to={`/${user.username}`} className="user" onClick={clearInput}>
            {cardContent}
          </Link>
        ) : (
          <div className="user">{cardContent}</div>
        )}
      </animated.div>
    </>
  );
}
