export default function ToggleChoice({ showLogin, setShowLogin }) {
  return (
    <>
      <div className="or">
        <hr />
        <span>OU</span>
        <hr />
      </div>
      <div className="toggle_link">
        <div className="goto">
          {showLogin ? (
            <p>
              Pas encore de compte ?{" "}
              <div className="toggle_link">
                <a href="#" onClick={() => setShowLogin(!showLogin)}>
                  Inscription
                </a>
              </div>
            </p>
          ) : (
            <p>
              Vous avez déjà un compte ?{" "}
              <div className="toggle_link">
                <a href="#" onClick={() => setShowLogin(!showLogin)}>
                  Connexion
                </a>
              </div>
            </p>
          )}
        </div>
      </div>
    </>
  );
}
