import { animated } from "react-spring";
import { displayEventDate } from "../../../utils/helpers/eventHelper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { translateY100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function OrganizedEvents({ events }) {
  const animation = translateY100();

  return (
    <animated.div style={animation} className="ogranized_events">
      <div className="organized_events_section">
        <h2 className="text_gradient">Événements créés :</h2>
        <ul>
          {events.map((event) => (
            <li key={event._id}>
              <div className="event_card">
                <img
                  src={`${api_url}/uploads/events/${event.event_picture}`}
                  alt="event thumbnail"
                />
                <p>{event.event_title}</p>
                <p>{event.event_location_formatted}</p>
                <p>{displayEventDate(event.event_date)}</p>
                <Link to={"/eventstudio/" + event._id}>
                  <FontAwesomeIcon icon={faPen} />
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </animated.div>
  );
}
