import { Link } from "react-router-dom";
import GradientButton from "../../Common/Buttons/GradientButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export function SuggestionCard({ user }) {
  return (
    <>
      <div className="suggestion">
        <Link to={`/${user.username}`} className="user">
          <div className="profile_picture">
            <img
              src={`${api_url}/uploads/profilepics/${user.profilepic}`}
              alt="profil"
            />
          </div>
          <p className="username">@{user.username}</p>
        </Link>
        <GradientButton round={true} icon={faPlus} />
      </div>
    </>
  );
}
