import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleInfo,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { animated } from "react-spring";
import { translateYMinus100 } from "../../../utils/animations/animations";
import Loader from "../../../components/Common/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import SingleUserPicture from "../../../components/Entities/User/SingleUserPicture";
import MultipleUserPictures from "../../../components/Entities/User/MultipleUserPictures";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import { formatEventTitle } from "../../../utils/helpers/eventHelper";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import {
  addUserToConv,
  updateConversation,
} from "../../../utils/fetchs/conversationFetchs";

export function ChatboxChatHeader({
  activeConv,
  actualUser,
  showThirdSection,
  setShowThirdSection,
  showChat,
  setShowChat,
}) {
  const [participants, setParticipants] = useState([]);
  const { showConvFirst, setShowConvFirst } = useContext(ChatboxContext);
  const animation = translateYMinus100();
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (activeConv && activeConv.participants) {
      setParticipants(activeConv.participants);
    }
  }, [activeConv]);

  const handleArrowClick = () => {
    setShowChat(!showChat);
    if (showConvFirst) {
      setShowConvFirst(false);
    }
  };
  const addUserToConversation = async () => {
    const currentParticipantIds = participants.map(
      (participant) => participant._id
    );
    const updatedParticipants = [...currentParticipantIds, actualUser._id];

    const updatedConv = await addUserToConv(actualUser, activeConv);

    if (updatedConv) {
      // Met à jour les participants après la réussite de l'ajout
      setParticipants(updatedParticipants);
      navigate("/chatbox");
    } else {
      console.error("Échec de la mise à jour de la conversation");
    }
  };
  const convType = activeConv.type;
  const otherParticipant =
    convType === "private"
      ? participants.find((p) => p._id !== actualUser._id)
      : null;

  if (!participants || participants.length === 0) {
    return <Loader />;
  }
  console.log(otherParticipant);
  return (
    <>
      <animated.div style={animation} className="chatbox_right_header">
        <div className="chatbox_flex">
          {mobileDisplay && showChat ? (
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleArrowClick} />
          ) : null}
          {convType === "private" &&
            otherParticipant &&
            otherParticipant.username && (
              <Link to={`/${otherParticipant.username}`}>
                <div className="chatbox_header_container">
                  <div className="chatbox_user_pp">
                    <SingleUserPicture user={otherParticipant} />
                  </div>
                  <h2>
                    @{otherParticipant.username}
                    <br />
                    <span>Connecté(e)</span>
                  </h2>
                </div>
              </Link>
            )}
          {convType === "group" && (
            <div className="chatbox_header_container">
              <ul className="chatbox_header_friends_list">
                <MultipleUserPictures
                  users={participants.filter((p, i) => i < 3)}
                />
              </ul>

              <h2>
                {generateConversationTitle(activeConv, actualUser)}
                <br />
                {participants.length > 3 && (
                  <span>
                    et {participants.length - 3} autres y participent.
                  </span>
                )}
              </h2>
            </div>
          )}
          {convType === "event" && (
            <div className="chatbox_header_container">
              <p>{activeConv.title}</p>
            </div>
          )}
        </div>
        {!showChat && ( // If not define then we are not on chatbox page
          <GradientButton
            text={"Rejoindre la conversation"}
            icon={faMessage}
            onClick={addUserToConversation}
          />
        )}
        <FontAwesomeIcon
          icon={faCircleInfo}
          onClick={() => setShowThirdSection(false)}
        />
      </animated.div>
      {convType === "event" && <div className="chatbox_header_event"></div>}
    </>
  );
}
