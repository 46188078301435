import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faBookmark,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkEmpty } from "@fortawesome/free-regular-svg-icons";
import { updateUserInfo } from "../../../../utils/fetchs/userFetchs";
import dayjs from "dayjs";
import ShareMediasCard from "./ShareMediasCard";
import { ShareMessage } from "../../../Modals/ShareMessage/ShareMessage";

export function PublicationFooter({ publication, loggedInUser, event }) {
  const wrapperRef = useRef(null);
  const [openShare, setOpenShare] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenShare(false); // Fermer le composant ShareMediasCard lorsque l'on clique en dehors
      }
    };

    // Ajouter un gestionnaire d'événements sur le document entier pour détecter les clics en dehors du composant
    document.addEventListener("mousedown", handleClickOutside);

    // Nettoyer le gestionnaire d'événements lors du démontage du composant
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <div className="post_event between">
        <div className="reaction_wrapper">
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="icon"
            onClick={() => setOpenModal(true)}
          />
          <FontAwesomeIcon
            icon={faBookmark}
            className="icon"
            onClick={
              {
                /*() => handleSavingPublication()*/
              }
            }
          />
          <FontAwesomeIcon
            icon={faShareNodes}
            className="icon"
            onClick={() => setOpenShare(!openShare)}
          />
          {openShare ? (
            <ShareMediasCard ref={wrapperRef} event={event} />
          ) : null}
        </div>
        <p className="post_time">
          {dayjs(publication.creation_date).format("dddd HH:mm")}
        </p>
        {openModal ? (
          <ShareMessage
            user={loggedInUser}
            shouldBeOpen={openModal}
            setShouldBeOpen={setOpenModal}
            sharedPubli={publication}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
