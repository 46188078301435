import React, { useEffect, useState } from "react";
import TicketSlider from "./TicketSlider";
import PostDetails from "./PostDetails";
import ShareEventList from "./ShareEventList";
import MapInner from "../../../../components/Entities/Map/MapInner";
import Loader from "../../../../components/Common/Loader/Loader";
import { translateXMinus30 } from "../../../../utils/animations/animations";
import { useUser } from "../../../../utils/contexts/userContext";
import { getTicketsFromEventId } from "../../../../utils/fetchs/ticketFetchs";
import { animated } from "react-spring";
import { MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import PostConv from "./PostConv";
import { getConversationByEventId } from "../../../../utils/fetchs/conversationFetchs";
import { checkUserParticipation } from "../../../../utils/fetchs/saleFetch";

export default function PostInfos({ event, creator }) {
  const { user } = useUser();
  const [tickets, setTickets] = useState(null);

  const [bounds, setBounds] = useState(null);
  const animation = translateXMinus30();
  const [eventConv, setEventConv] = useState(null);
  const [loggedInUserIsParticipating, setLoggedInUserIsParticipating] =
    useState(false);
  const [isCreator, setIsCreator] = useState(
    event.organiser_id === creator._id
  );

  console.log(isCreator);
  // Check if user participates
  useEffect(() => {
    const checkParticipation = async () => {
      const response = await checkUserParticipation(user, event._id);
      if (response && response.length > 0) {
        setLoggedInUserIsParticipating(true);
      }
    };

    checkParticipation();
  }, [event._id, user]);

  // Fetch events tickets
  useEffect(() => {
    getTicketsFromEventId(event._id).then((tickets) => setTickets(tickets));
  }, [event._id]);

  // Fetch event conv
  useEffect(() => {
    getConversationByEventId(event._id, user).then((conv) => {
      setEventConv(conv);
    });
  }, [event._id, user]);

  if (!tickets) {
    return <Loader />;
  }

  const position = [
    event.event_location_coordinates[0],
    event.event_location_coordinates[1],
  ];

  return (
    <>
      <animated.div style={animation}>
        <div className="info">
          <h1 className="text_gradient">{event.event_title}</h1>
          <PostDetails event={event} creator={creator} />

          <div className="hrgradient top" />
          <p className="description">{event.description}</p>
          {/*
          
          
          */}
          {!loggedInUserIsParticipating && tickets.length > 0 && (
            <TicketSlider
              event={event}
              tickets={tickets}
              onReservation={() => setLoggedInUserIsParticipating(true)}
            />
          )}
          {loggedInUserIsParticipating && eventConv && (
            <PostConv eventConv={eventConv} user={user} />
          )}

          <div className="hrwrapper">
            <p>Lieux :</p>
            <div className="hrgradient" />
            <p>🇫🇷 {event.event_location_formatted}</p>

            <div className="map_container">
              <MapContainer
                center={position}
                zoom={13}
                style={{ height: "300px", width: "100%" }}
              >
                <MapInner events={[event]} handleBounds={setBounds} />
              </MapContainer>
            </div>
          </div>
          {user && user.email && <ShareEventList user={user} />}
          <div className="hrwrapper">
            <p>D'autres événements qui pourraient vous plaire :</p>
            <div className="hrgradient" />
          </div>
          <p>Coming soon ⏱️</p>
        </div>
      </animated.div>
    </>
  );
}
