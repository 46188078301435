import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../../components/Common/Buttons/GradientButton";

export default function ModifyNotif() {
  return (
    <>
      <section className="notification">
        <article className="general">
          <p>Notifications push</p>
          <div className="general_container">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
            <p>Tout mettre en pause</p>
          </div>
        </article>
        <article className="likes">
          <p>J'aime</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" /> Des personnes que je suis
          </label>
          <label>
            <input type="checkbox" defaultChecked /> De tout le monde
          </label>
          <hr />
        </article>
        <article className="comments">
          <p>Commentaires</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" /> Des personnes que je suis
          </label>
          <label>
            <input type="checkbox" defaultChecked /> De tout le monde
          </label>
          <hr />
        </article>
        <article className="comment_likes">
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="Recommandations">
          <p>Recommandations</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="new_follows">
          <p>Nouveaux abonnés</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="message_invite">
          <p>Invitations par message</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="messages">
          <p>Messages</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="event_invite">
          <p>Invitations aux événements</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <article className="event_reminder">
          <p>Rappel d'événement</p>
          <label>
            <input type="checkbox" /> Non
          </label>
          <label>
            <input type="checkbox" defaultChecked /> Oui
          </label>
          <hr />
        </article>
        <GradientButton type={"save"} />
      </section>
    </>
  );
}
