import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import Loader from "../../components/Common/Loader/Loader";
import MapInner from "../../components/Entities/Map/MapInner";
import { MapContainer } from "react-leaflet";
import { getAllEvents } from "../../utils/fetchs/eventFetchs";
import { isInsideBounds } from "../../utils/helpers/mapHelper";
import "leaflet/dist/leaflet.css";
import EventCard from "../../components/Entities/Event/EventCard";

export default function Map({ focusedEvent }) {
  const cssPath = "/assets/css/pages/map.css";
  const title = "Wive | Trouver votre prochain évènement";

  const [events, setEvents] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);

  const updateVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    // Update the vh variable on load and resize
    updateVh();
    window.addEventListener("resize", updateVh);

    return () => {
      window.removeEventListener("resize", updateVh);
    };
  }, []);

  useEffect(() => {
    setCenter([48, 2.3]);
    setZoom(6);
  }, []);

  useEffect(() => {
    getAllEvents().then((events) => setEvents(events));
  }, []);

  if (!events) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ title, cssPath })}
      <section className="map_section">
        {zoom && center.length > 0 && (
          <MapContainer
            className="map_container"
            center={center}
            zoom={zoom}
            style={{
              height: "calc(var(--vh, 1vh) * 100 - 100px)",
              width: "100%",
              maxHeight: "calc(var(--vh, 1vh) * 100 - 100px)",
            }}
          >
            {/* Add the new SetViewOnClick component inside MapContainer */}
            <MapInner
              events={focusedEvent ? [focusedEvent] : events}
              handleBounds={setBounds}
            ></MapInner>
          </MapContainer>
        )}
      </section>

      <div className="event_list_container">
        <ul>
          {events
            .filter((event) =>
              isInsideBounds(bounds, event.event_location_coordinates)
            )
            .map((event) => (
              <EventCard event={event} />
            ))}
        </ul>
      </div>
    </>
  );
}
