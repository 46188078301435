import React from "react";
import GradientButton from "../../Buttons/GradientButton";
import NavItems from "./NavItems";
import SearchInput from "./SearchInput";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import { faCompass, faUser } from "@fortawesome/free-solid-svg-icons";

export default function NavHeader({
  isLoggedIn,
  mobileDisplay,
  isSearchPage,
  isChatbox,
}) {
  const navbarHeight = scrollY > 50 ? "80px" : "100px";
  const navbarAnimation = useSpring({
    from: { transform: "translateY(-100%)" },
    to: { transform: "translateY(0%)", height: navbarHeight },
    config: config.slow,
  });
  return (
    <>
      <animated.nav style={navbarAnimation} className="navbar">
        <div className="nav_wrapper">
          <Link to={"/"}>
            <img
              className="logo_wive"
              src="/assets/images/logo.png"
              alt="Logo Wive"
            />
          </Link>
          {(isLoggedIn && !mobileDisplay) || (mobileDisplay && isSearchPage) ? (
            <SearchInput />
          ) : null}
          {isLoggedIn ? (
            <NavItems isChatbox={isChatbox} />
          ) : (
            <GradientButton
              icon={faUser}
              text={"Connexion"}
              link={"/login-register?feed=true"}
              padding={"10px"}
            />
          )}
        </div>
      </animated.nav>
    </>
  );
}
