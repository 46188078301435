import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function getEventsFromUserId(userId) {
  try {
    const response = await fetch(`${api_url}/event/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to get event by its id");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getEventById(eventId) {
  try {
    const response = await fetch(`${api_url}/event/get/${eventId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to get event by its id");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function updateEvent(eventId, eventData, user) {
  try {
    const tk = await user.token;
    console.log(eventId);
    const response = await fetch(`${api_url}/event/update/${eventId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tk,
      },
      body: JSON.stringify(eventData),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to update event");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function createEvent(eventData, user) {
  try {
    const tk = await user.token;
    console.log(eventData);
    const response = await fetch(`${api_url}/event/create/${user._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tk,
      },
      body: JSON.stringify(eventData),
    });

    switch (response.status) {
      case 201:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to create event");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getAllEvents() {
  try {
    const response = await fetch(`${api_url}/event/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 201:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to retreive all events");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getPublications(eventId) {
  try {
    const response = await fetch(`${api_url}/event/${eventId}/publications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 404:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to retreive all events");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getEventsFromParticipantId(userId) {
  try {
    const response = await fetch(`${api_url}/event/participants/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to get events by participant id");
    }
  } catch (error) {
    console.error(error.message);
  }
}
