import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import GradientButton from "../../components/Common/Buttons/GradientButton";
import { useUser } from "../../utils/contexts/userContext";
import {
  verifyEmail,
  sendVerificationEmail,
} from "../../utils/fetchs/authFetchs";
import Loader from "../../components/Common/Loader/Loader";
import updateHead from "../../utils/helpers/updatePageHead";
import SingleUserPicture from "../../components/Entities/User/SingleUserPicture";
import { getUserByUserId } from "../../utils/fetchs/userFetchs";
import { UserCard } from "../../components/Entities/User/UserCard";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [boss, setBoss] = useState(null);
  const { user, updateUser } = useUser();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const cssPath = "assets/css/pages/login_register.css";
  const bossId = "653189026fc3e182fb0cc01a"; // ID de Martin

  useEffect(() => {
    // Ajout d'un log pour vérifier l'utilisateur au début
    console.log("User at start of useEffect:", user);

    const verifyUserEmail = async () => {
      console.log("Token:", token);
      console.log("User in verifyUserEmail:", user);

      // Attendre que le contexte utilisateur soit initialisé
      if (!user || Object.keys(user).length === 0) {
        setIsFetching(false);
        return;
      }

      if (!token && !user.email) {
        navigate("/login-register");
        return;
      }

      if (token) {
        try {
          const response = await verifyEmail(token);
          if (response) {
            updateUser({
              ...user,
              verified: true, // Met à jour l'utilisateur comme vérifié
            });
            navigate("/");
          } else {
            setErrorMessage("Échec de la vérification de l'email");
          }
        } catch (error) {
          console.error("Error verifying email:", error);
          setErrorMessage(
            "Une erreur s'est produite lors de la vérification de l'email"
          );
        }
      }
      setIsFetching(false);
    };

    verifyUserEmail();
  }, [user, location, navigate]);

  const handleSendVerificationEmail = async () => {
    if (user && user.email) {
      try {
        const force = true;
        const response = await sendVerificationEmail(user.email, force);

        if (response) {
          setErrorMessage("Le mail à été envoyé.");
        } else {
          setErrorMessage("Erreur lors de la mise à jour de l'utilisateur");
        }
      } catch (error) {
        console.error("Error sending verification email:", error);
        setErrorMessage(
          "Une erreur s'est produite lors de l'envoi de l'email de vérification"
        );
      }
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const bossData = await getUserByUserId(bossId);
        setBoss(bossData);
      } catch (error) {
        console.error("Erreur lors du fetch des utilisateurs :", error);
      }
    };

    fetchUsers(); // Appel de la fonction dès le montage du composant
  }, []);

  useEffect(() => {
    const sendEmail = async () => {
      await sendVerificationEmail(user.email);
    };

    if (!token && user.email) {
      sendEmail();
    }
  }, [user]);

  if (isFetching) {
    return <Loader />; // Rendre le composant Loader pendant la vérification
  }

  return (
    <>
      {updateHead({ cssPath })}

      <div className="form_content twofa">
        {user.email && (
          <>
            <SingleUserPicture user={user} />
            <p className="hello">Bonjour, {user.username} 👋</p>
            <h1>Bienvenue sur la Beta de Wive</h1>
            <p>
              Pour pouvoir accéder aux fonctionnalités du site, merci de
              vérifier ton profil en cliquant sur le lien reçu par mail.
              N'oublie pas de vérifier tes spams.
            </p>
            <p>
              <br />
              Ceci n'est qu'une Beta, n'hésites pas à me contacter directement
              sur Wive en cas de problème.
            </p>
            <p>
              <br />
              Comme vous avez créé votre compte avant l'Alpha (le 12 décembre),
              vous avez obtenu un profil Wive Founder.
            </p>
            {boss && <UserCard user={boss} />}
            <p>{errorMessage}</p>

            <GradientButton
              text="Renvoyer un email"
              onClick={handleSendVerificationEmail}
            />
          </>
        )}
      </div>
    </>
  );
}
