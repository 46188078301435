export function generateConversationTitle(conversation, user) {
    if (conversation.title) {
      return conversation.title;
    } else {
      return conversation.participants
        .filter((p) => p._id !== user._id)
        .slice(0, 2)
        .map((p) => `@${p.username}`)
        .join(", ");
    }
  };
