/**
 * GradientButton est un composant bouton avec un dégradé de couleurs.
 *
 * @param {Object} icon - L'icône FontAwesome à afficher dans le bouton. Par défaut, c'est 'faCheck'. Si 'none', aucune icône n'est affichée.
 * @param {string} text - Le texte à afficher dans le bouton. Par défaut, c'est 'Valider'.
 * @param {string} link - Le lien vers lequel le bouton doit rediriger. Si non défini, le bouton ne redirige pas mais déclenche l'événement onClick.
 * @param {string} padding - Le padding à appliquer au bouton. Si non défini, aucun padding n'est appliqué.
 * @param {function} onClick - La fonction à exécuter lorsque le bouton est cliqué. N'est utilisée que si 'link' n'est pas défini.
 * @param {boolean} round - Si true, alors le bouton sera rond, pour un rendu optimal, définir text a "none" et ne mettre qu'un icon. Se referer a chatbox.
 *
 * @example
 * <GradientButton
 *   icon={faSave}
 *   text="Sauvegarder"
 *   padding="10px"
 *   onClick={handleSave}
 * />
 *
 * @returns Le composant GradientButton et execute la fonction.
 */
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFloppyDisk,
  faTrash,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import updateHead from "../../../utils/helpers/updatePageHead";

export default function GradientButton({
  type,
  icon,
  text,
  link,
  padding,
  onClick,
  round,
  size,
  filled,
  textfirst,
}) {
  const cssPath = "/assets/css/components/gradient_buttons.css";

  if (round === true) {
    text = "none";
    const roundStyle = {};
  }

  if (type === "validate") {
    icon = faCheck;
    text = "Valider";
  } else if (type === "delete") {
    icon = faTrash;
    text = "Supprimer";
  } else if (type === "save") {
    icon = faFloppyDisk;
    text = "Sauvegarder";
  } else if (type === "like") {
    icon = filled ? faCheck : faHeart;
    text = "Intéressé.e";
  }

  const buttonContent = (
    <>
      {textfirst && text !== "none" && text}
      {icon !== "none" && (
        <FontAwesomeIcon
          icon={icon}
          className={`icon ${textfirst ? "textfirst" : ""}`}
        />
      )}
      {!textfirst && text !== "none" && text}
    </>
  );

  const innerButton = (
    <>
      <div
        className={`gradient_btn ${round ? "round" : ""}`}
        style={{
          padding: padding ? padding : "",
          background: filled ? "var(--clr)" : "",
          ...(round && {
            width: size ? size : "25px",
            height: size ? size : "25px",
          }),
        }}
      >
        <button>{buttonContent}</button>
      </div>
    </>
  );

  return (
    <>
      {updateHead({ cssPath })}
      {link ? (
        <Link
          to={link}
          className={`gradient_btn_outline ${round ? "round" : ""}`}
        >
          {innerButton}
        </Link>
      ) : (
        <div
          onClick={onClick}
          className={`gradient_btn_outline ${round ? "round" : ""}`}
        >
          {innerButton}
        </div>
      )}
    </>
  );
}
