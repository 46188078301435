export function getApiUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr/api"
    : "http://localhost:8080/api";
}

export function getImgUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr"
    : "http://localhost:8080";
}
