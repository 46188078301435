import React from "react";
import ReactDOM from "react-dom/client";
import { Portal } from "react-portal";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserProvider } from "./utils/contexts/userContext";
import { SocketProvider } from "./utils/contexts/socketContext";
import { ChatboxProvider } from "./utils/contexts/chatboxContext";

const root = document.getElementById("root");
const rootContainer = ReactDOM.createRoot(root);

// Check if browser supports service workers
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("./utils/contexts/serviceWorker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

rootContainer.render(
  <BrowserRouter>
    <UserProvider>
      <SocketProvider>
        <ChatboxProvider>
          <App />
          <Portal className="portal"></Portal>
        </ChatboxProvider>
      </SocketProvider>
    </UserProvider>
  </BrowserRouter>
);
