import L from "leaflet";

export function setMapView(map, focusedEvent) {
if (focusedEvent && focusedEvent.event_location_coordinates) {
    const [lat, lng] = focusedEvent.event_location_coordinates;
    map.setView([lat, lng], 13);
} else {
    map.setView([48, 2.3], 6); 
}
};

export function isInsideBounds(bounds, [lat_e, lng_e]) {
if (!bounds) {
    return true;
} else {
    return bounds.contains(L.latLng(lat_e, lng_e));
}
}