import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { updateUserInfo } from "../../../utils/fetchs/userFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function ModifyPassword({ user }) {
  const [previousPassword, setPreviousPassword] = useState(null);
  const [newPasswordv1, setNewPasswordv1] = useState(null);
  const [newPasswordv2, setNewPasswordv2] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    display: false,
    message: "",
  });
  const { updateUser, logout } = useUser();
  const navigate = useNavigate();

  const updateUserStatus = async () => {
    if (!previousPassword || !newPasswordv1 || !newPasswordv2) {
      setErrorMessage({
        display: true,
        message: "Tous les champs doivent être renseignés...",
      });
    } else {
      if (newPasswordv1 !== newPasswordv2) {
        setErrorMessage({
          display: true,
          message: "Les mots de passe ne correpondent pas...",
        });
        return;
      }

      const updatedUser = await updateUserInfo(user, "password", newPasswordv1);

      if (!updatedUser) {
        setErrorMessage({
          display: true,
          message:
            "Une erreur s'est produite lors de la sauvegarde de votre nouveau mot de passe, merci de réessayer plustard",
        });
        return;
      }
      setErrorMessage({
        display: true,
        message: "Votre nouveau mot de passe à bien été sauvegardé ;)",
      });
      // if succeed update password in localstorage user
      user.password = updatedUser.password;
      // update user in local storage for next useUser()
      updateUser(user, "password", updatedUser.password);

      // logout
      logout();
      navigate("/presentation"); //TODO better handle redirection
    }
  };

  return (
    <>
      <section className="password">
        <article className="user">
          <div className="second_left_col">
            <img
              src={`${api_url}/uploads/profilepics/${user.profilepic}`}
              alt="profile_picture"
            />
          </div>
          <div className="second_right_col">
            <p>@{user.username}</p>
          </div>
        </article>
        <article className="old_password">
          <div className="second_left_col">
            <p>Ancien mot de passe</p>
          </div>
          <div className="second_right_col">
            <input
              type="text"
              placeholder="Ancien mot de passe"
              onChange={(e) => setPreviousPassword(e.target.value)}
            />
          </div>
        </article>
        <article className="new_password">
          <div className="second_left_col">
            <p>Nouveau mot de passe</p>
          </div>
          <div className="second_right_col">
            <input
              type="text"
              placeholder="Nouveau mot de passe"
              onChange={(e) => setNewPasswordv1(e.target.value)}
            />
          </div>
        </article>
        <article className="second_new_password">
          <div className="second_left_col">
            <p>Confirmer le nouveau mot de passe</p>
          </div>
          <div className="second_right_col">
            <input
              type="text"
              placeholder="Confirmer votre mot de passe"
              onChange={(e) => setNewPasswordv2(e.target.value)}
            />
          </div>
        </article>
        <GradientButton type={"save"} onClick={() => updateUserStatus()} />
        <p className="forgot">Mot de passe oublié ?</p>
        {errorMessage.display && <p>{errorMessage.message}</p>}
      </section>
    </>
  );
}
