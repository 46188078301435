import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUser } from "../../utils/contexts/userContext";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import updateHead from "../../utils/helpers/updatePageHead";
import ModifyAccount from "./Subpages/ModifyAccount";
import ModifyNotif from "./Subpages/ModifyNotif";
import VerifProfile from "./Subpages/VerifProfile";
import ModifyPassword from "./Subpages/ModifyPassword";
import Feedback from "./Subpages/Feedback";

export default function Account() {
  const cssPath = "/assets/css/pages/account.css";
  const title = "Wive | Modifier votre compte";

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultSection = params.get("section") || "account";

  const { user, logout } = useUser();
  const [selectedSection, setSelectedSection] = useState(defaultSection);

  const logoutUser = () => {
    logout();
    navigate("/presentation");
  };

  return (
    <>
      <main>
        <BackgroundGradientLur />
        {updateHead({ title, cssPath })}
        <div className="left_col">
          <p onClick={() => setSelectedSection("account")}>
            Modifier votre compte
          </p>
          <div className="hrgradient"></div>

          <p onClick={() => setSelectedSection("password")}>
            Changer de mot de passe
          </p>
          <div className="hrgradient"></div>

          {/*<Link to="/verifpro">
            <p>Compte professionnel</p>
          </Link>
          <div className="hrgradient"></div>

          <p onClick={() => setSelectedSection("verifprofile")}>
            Vérifier votre profil
          </p>
          <div className="hrgradient"></div>

          <p onClick={() => setSelectedSection("notif")}>
            Notifications
          </p>
          <div className="hrgradient"></div>

          <p>
            Confidentialité et sécurité
          </p>
          <div className="hrgradient"></div>*/}

          <p onClick={() => setSelectedSection("feedback")}>
            Signaler un problème
          </p>
          <div className="hrgradient"></div>

          <p onClick={() => logoutUser()}>Déconnexion</p>
        </div>

        <div className="right_col">
          {(() => {
            switch (selectedSection) {
              case "account":
                return <ModifyAccount user={user} />;
              case "notif":
                return <ModifyNotif />;
              case "password":
                return <ModifyPassword user={user} />;
              case "feedback":
                return <Feedback />;
              case "verifprofile":
                return <VerifProfile user={user} />;
              default:
                return null;
            }
          })()}
        </div>
      </main>
    </>
  );
}
