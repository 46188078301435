import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { translateX100 } from "../../../utils/animations/animations";
import { SenderMessage, ShareMessage, UserMessage } from "./ChatboxMessages";
import Loader from "../../../components/Common/Loader/Loader";
import {
  markMessageAsRead,
  getMessagesByConversationId,
} from "../../../utils/fetchs/messagesFetchs";
import { useSocket } from "../../../utils/contexts/socketContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";

export function ChatboxChat({ activeConv, actualUser }) {
  const [messages, setMessages] = useState(null);
  const [senders, setSenders] = useState(null);
  const animation = translateX100();
  const socket = useSocket();
  const [events, setEvents] = useState(null);

  console.log(activeConv);

  useEffect(() => {
    socket.emit("joinConversation", activeConv._id);
    socket.on("message", (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.emit("leaveConversation", activeConv._id);
    };
  }, []);

  useEffect(() => {
    setSenders(null);
    setMessages(null);
    setEvents(null);

    if (activeConv) {
      getMessagesByConversationId(activeConv._id).then((messages) => {
        setMessages(messages);

        const fetchEvents = async () => {
          const eventIds = [
            ...new Set(
              messages.map((msg) => msg.sharedEventId).filter((id) => id)
            ),
          ];
          if (eventIds.length > 0) {
            const eventPromises = eventIds.map((id) => getEventById(id));
            const eventResults = await Promise.all(eventPromises);
            const eventsMap = eventResults.reduce((acc, event) => {
              acc[event._id] = event;
              return acc;
            }, {});
            setEvents(eventsMap);
          } else {
            setEvents({}); // Set an empty object if no events to fetch
          }
        };

        fetchEvents();
      });

      activeConv.participants.forEach((participant) => {
        setSenders((prevData) => ({
          ...prevData,
          [participant._id]: participant,
        }));
      });
    }
  }, [activeConv]);

  useEffect(() => {
    if (activeConv) {
      markMessageAsRead(activeConv._id);
    }
  }, [messages, activeConv._id]);

  if (!activeConv || !messages || !senders || events === null) {
    return <Loader />;
  }

  return (
    <animated.div style={animation} className="chatbox_chatbox">
      {[...messages].reverse().map((msg, index) => {
        const sender = senders[msg.senderId];
        if (msg.sharedEventId) {
          const event = events[msg.sharedEventId];
          return <ShareMessage key={index} msg={msg} event={event} />;
        } else if (msg.senderId === actualUser._id) {
          return <UserMessage key={index} user={actualUser} message={msg} />;
        } else {
          return <SenderMessage key={index} sndr={sender} message={msg} />;
        }
      })}
    </animated.div>
  );
}
