import { getImgUrl } from "../../../utils/contexts/apiContext";

const api_url = getImgUrl();

export default function MultipleUserPictures({ users }) {
  return (
    <>
      {users.map((user) => (
        <img
          key={user._id}
          src={`${api_url}/uploads/profilepics/${user.profilepic}`}
          alt="profile_picture"
          className="chatbox_sender_pp chatbox_cover"
        />
      ))}
    </>
  );
}
