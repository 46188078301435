import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import updateHead from "../../utils/helpers/updatePageHead";
import Loader from "../../components/Common/Loader/Loader";
import TicketCol from "./Editing/TicketEditing";
import PublicationCol from "./Editing/PublicationEditing";
import EventSection from "./Editing/EventEditing";
import CreaEventCard from "../../components/Entities/Event/CreaEventCard/CreaEventCard";

import ConversationCol from "./Editing/ConversationEditing";
import CompletionCircle from "./tmpl/CompletionCircle";
import { calculateCompletion } from "../../utils/helpers/userHelper";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getEventById } from "../../utils/fetchs/eventFetchs";
import { useUser } from "../../utils/contexts/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faPager,
  faPenToSquare,
  faTicket,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

export default function EventStudio() {
  const cssPath = "/assets/css/pages/event_studio.css";

  const { eventId } = useParams();
  const { user } = useUser();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const ticketRef = useRef(null);
  const publicationRef = useRef(null);
  const eventRef = useRef(null);
  const conversationRef = useRef(null);
  if (user.token && !user.verified) {
    navigate("/two-factor-auth");
  }

  useEffect(() => {
    getEventById(eventId, user).then((data) => setEvent(data));
  }, [eventId]);

  // Show loader while event data is being fetched
  if (!event) {
    return <Loader />;
  }

  // Set page title
  const title = `Wive | ${event.event_title}`;

  // Function to scroll to a reference within the page
  const scrollToRef = (ref, applyOffset = false) => {
    if (ref.current) {
      // Apply offset for navbar height if needed
      const navbarHeight = applyOffset ? 100 : 0;
      const offsetTop = ref.current.offsetTop - navbarHeight;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {updateHead({ title, cssPath })}
      <BackgroundGradientLur />
      <main>
        <div className="left_col">
          <p onClick={() => scrollToRef(eventRef)}>
            <FontAwesomeIcon icon={faPager} className="icon" />
            Votre événement
            <CompletionCircle percentage={calculateCompletion(event)} />
          </p>
          <div className="hrgradient" />

          <p onClick={() => scrollToRef(publicationRef)}>
            <FontAwesomeIcon icon={faPenToSquare} className="icon" />
            Vos publications
            <CompletionCircle
              percentage={calculateCompletion(event.publications)}
            />
          </p>
          <div className="hrgradient"></div>

          <p onClick={() => scrollToRef(ticketRef, true)}>
            <FontAwesomeIcon
              icon={faTicket}
              className="icon"
              style={{ color: "white" }}
            />
            Vos billets
            <CompletionCircle percentage={calculateCompletion(event.billets)} />
          </p>
          <div className="hrgradient" />

          <p onClick={() => scrollToRef(conversationRef)}>
            <FontAwesomeIcon icon={faMessage} className="icon" />
            Votre conversation
            <CompletionCircle
              percentage={calculateCompletion(event.conversations)}
            />
          </p>
          <div className="hrgradient" />
          <p onClick={() => scrollToRef(eventRef)}>
            <FontAwesomeIcon icon={faChartSimple} className="icon" />
            Vos statistiques d'événement
          </p>
          <div className="hrgradient" />
        </div>

        <div className="right_col">
          <div ref={eventRef}>
            <CreaEventCard event={event} />
          </div>
          <div ref={publicationRef}>
            <PublicationCol event={event} />
          </div>
          <div ref={ticketRef}>
            <TicketCol event={event} />
          </div>
          <div ref={conversationRef}>
            <ConversationCol event={event} user={user} />
          </div>
        </div>
      </main>
    </>
  );
}
