import React from "react";
import dayjs from "dayjs";

export default function CalendarCard({ event }) {
  const dateToMonth = (dateString) => {
    return dayjs(dateString).format("MMM");
  };
  const dateToDay = (dateString) => {
    return dayjs(dateString).format("DD");
  };
  return (
    <>
      <div className="calendar_background">
        <div className="calendar_inner_background">
          <p>{dateToMonth(event.event_date)}</p>
        </div>
        <p className="calendar_text">{dateToDay(event.event_date)}</p>
      </div>
    </>
  );
}
